import main from './web-main.png';
import labels from './labelsstack.png';
import appstorebadge from './appstorebadge.svg';
import playstorebadge from './google-play-badge.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="left">
        <h1 style={{  color: '#2b7fff', fontSize: '48px' }}>Smart Labels</h1>
        <h2 style={{ color: '#555', fontWeight: '300', marginTop: '-12px', marginBottom: '40px', lineHeight: '36px' }}>Stay organized with color-coded QR Smart Labels<br></br> Manage storage and inventory from your phone</h2>
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          <a href="https://apps.apple.com/us/app/smart-labels/id1555535491" rel="nofollow noopener" target="_blank">
            <img style={{ height: '68px', marginTop: '16px' }} src={appstorebadge} />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.arwong09.SmartLabels" rel="nofollow noopener" target="_blank">
            <img style={{ height: '100px'}} src={playstorebadge} />
          </a>
        </div>
        <img style={{ height: '240px', marginTop: '80px' }} src={labels} />
      </div>
      <div className="right">
        <a href="https://amzn.to/38NQZ7w" rel="nofollow noopener" target="_blank">
          Buy Labels on Amazon.com
        </a>
        <a href="mailto:help@qrsmartlabels.com" rel="nofollow noopener" target="_blank">
          Contact Us
        </a>
        <img style={{ maxHeight: '90vh', maxWidth: '90vw' }} src={main} />  
      </div>
    </div>
  );
}

export default App;
